.headerBannerCarousel {
	margin: 0 0 30px 0;
	position: relative;

	.owl-controls {
		position: absolute;
		bottom: 10px;
		right: 10px;

		.owl-dots {


			.owl-dot {

				display: inline-block;
				margin: 0 5px;
				padding: 5px;

				span {
					width: 10px;
					height: 10px;
					background: @blue;
					border-radius: 50%;
					display: inline-block;
					border: 1px solid #fff;
				}

				&.active {
					span {
						background-color: #fff;
						border-color: @blue;
					}
				}
			}
		}
	}
}

.headerBanner {

	position: relative;
	overflow: hidden;
	height: 310px;

	@media @tablet {
		margin-top: 1px;
	}

	.heading {
		position: relative;
		z-index: 2;
		display: inline-block;
		margin: 0;
		font-size: @default-font-size * 1.6;
		padding: 80px 60px 20px 20px;
		color: @blue;
		text-transform: uppercase;
		height: 310px;
		width: 320px;
		//overflow: hidden;

		@media @phablet {
			width: 360px;
			font-size: @default-font-size * 2;

		}

		@media @tablet {
			max-width: 380px;
			padding: 100px 100px 40px 40px;
		}

		span {
			display: inline-block;
			position: absolute;
			z-index: 3;
			padding: 0;
			max-width: 180px;
			bottom: 30px;
			left: 30px;
			width: 240px;

			@media @phablet {
				max-width: none;
			}
		}

		&:before {
			content: "";
			width: 1000px;
			height: 1000px;
			display: block;
			position: absolute;
			bottom: 0;
			right: 0;
			.transform(rotate(-30deg));
			.transform-origin(100% 100%);
			background: rgba(255, 255, 255, 0.7);
		}
	}

	.headerBannerBackground {
		display: block;
		content: "";
		position: absolute;
		z-index: 1;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background: 100% 0 no-repeat;
		background-size: cover;

		@media @tablet {
			//background-size: 100% auto;
			background-position: 100% 30%;
		}
	}
}