table {

	&.basic {

		&._vat {
			tr {
				th,
				td {
					vertical-align: top;
				}
			}
		}

		&._vam {
			tr {
				th,
				td {
					vertical-align: middle;
				}
			}
		}

		&._vab {
			tr {
				th,
				td {
					vertical-align: bottom;
				}
			}
		}

		&._tal {
			tr {
				th,
				td {
					text-align: left;
				}
			}
		}

		&._tac {
			tr {
				th,
				td {
					text-align: center;
				}
			}
		}

		&._tar {
			tr {
				th,
				td {
					text-align: right;
				}
			}
		}


		tr {

			th,
			td {
				vertical-align: middle;
				text-align: left;
				padding: 10px;

				&:first-child {
					padding-left: 0;
				}

				&:last-child {
					padding-right: 0;
				}

				h2 {
					&:first-child {
						margin-top: 0;
					}
				}

				&._vat {
					vertical-align: top;
				}

				&._vam {
					vertical-align: middle;
				}

				&._vab {
					vertical-align: bottom;
				}

				&._tal {
					text-align: left;
				}

				&._tac {
					text-align: center;
				}

				&._tar {
					text-align: right;
				}
			}

			&:first-child {

				th,
				td {
					padding-top: 0;
				}
			}

			&:last-child {

				th,
				td {
					padding-bottom: 0;
				}
			}

			th {

			}

			td {

			}
		}

		thead,
		tbody {

			tr {

				th,
				td {

				}

				th {

				}

				td {

				}
			}
		}

		thead {

			tr {

				th,
				td {

				}

				th {

				}

				td {

				}
			}
		}

		tbody {

			tr {

				th,
				td {

				}

				th {

				}

				td {

				}
			}
		}
	}
}