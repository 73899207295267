// Base Class Definition
// -------------------------

.@{fa-css-prefix} {
	display: inline-block;
	font: normal normal normal @fa-font-size-base/@fa-line-height-base FontAwesome; // shortening font declaration
	font-size: inherit; // can't have font-size inherit on line above, so need to override
	text-rendering: auto; // optimizelegibility throws things off #1094
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	margin: 0 0 0 10px;

	&:first-child {
		margin: 0 10px 0 0;
	}
}
