.footerCols {
	clear: both;
	background: @gray-lightest;
	padding: 25px 15px ;

	@media @phablet {
		padding-bottom: 10px;
	}

	h2 {
		margin-bottom: 10px;
		margin-top: 20px;

		&:first-child {
			margin-top: 0;
		}
	}

	h2 + ul {
		margin-top: 20px;
	}



	.footerContacts {
		line-height: 2em;
		.icon {
			margin: 0 7px 0 0;
			font-size: 14px;
		}
	}

	.address {
		a {
			color: @gray;
		}
	}
}
.socialLinksWrapper {
	clear: both;
	h2 {
		margin-right: 10px;
		margin-top: 20px !important;
		@media @phablet {
			display: inline;
		}
	}
	ul.socialLinks {
		margin:  0;
		@media @phablet {
			display: inline;
		}
	}
}
.socialLinks {
	margin-top: 0;

	li {

		display: inline-block;
		margin: 0;

		a {

			color: @gray-lighter;
			text-decoration: none;
			font-size: 20px;

			span {
				display: none;
			}

			&:hover {
				&.fb {
					color: #3B5998;
				}
				&.li {
					color: #0075B2;
				}
				&.tw {
					color: #1DA1F2;
				}
				&.yt {
					color: #cd201f;
				}
			}
		}
	}
}


footer {

	&.footer {

		margin-top: 20px;

		.footerLinks {

			list-style: none;
			border-bottom: 1px solid @gray-lighter;
			padding: 20px 0;

			@media @phablet {
				padding: 0;
			}

			li {

				@media @phablet {
					padding: 10px;
					display: inline-block;
				}

				a {

				}
			}
		}
	}
}