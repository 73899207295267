.breadcrumbs {

	font-size: 13px;
	margin-top: 15px;

	ol,
	ol li {
		list-style: none;
		display: inline;
	}

	a {
		text-decoration: none;
	}

	@media @tablet {
		padding: 0 15px 0 15px;
	}

	strong {
		font-weight: normal;
	}
}

.pageWrap {

	width: 100%;
	padding: 0 15px 20px 15px;

	@media @desktop-small {
		max-width: @page-width;
		margin: 0 auto;
		padding: 0 0 20px 0;
	}
}

.mainContent {
	padding: 0 0 40px 0;

	@media @tablet {
		padding: 30px 15px 50px 15px;
	}

	.breadcrumbs + & {
		padding-top: 10px;

		@media @tablet {
			padding-top: 20px;
		}
	}

	&.-no-borders {
		padding-left: 0;
		padding-right: 0;
	}
}

.contentCols {

	.contentCol,
	.columnLeft,
	.columnMain {
		margin-top: 30px;

		@media @tablet {
			margin-top: 0;
		}

		&:first-child {
			margin-top: 0;
		}
	}

	.sideCol,
	.columnLeft {

		*:first-child {
			margin-top: 0;
		}

		@media @tablet {
			float: left;
			width: 25%;
		}
	}

	.mainCol,
	.columnMain {

		@media @tablet {
			float: right;
			width: 73%;
		}

		.breadcrumbs {
			padding: 0;
			margin: 0 0 15px 0;
		}
	}
}