.list {
	list-style: none;

	li {
		margin: 10px 0 0 0;
		padding: 0 0 0 15px;
		position: relative;

		&:first-child {
			margin-top: 0;
		}

		&:before {
			content: "";
			display: inline-block;
			width: 6px;
			height: 6px;
			background: @gray;
			overflow: hidden;
			position: absolute;
			top: 4px;
			left: 0;
		}
	}

	&.-blue {
		li:before {
			background: @blue;
		}
	}

	&.-dots {
		li:before {
			border-radius: 50%;
		}
	}

	&.-bigger {
		li {
			padding-left: 20px;
			&:before {
				width: 8px;
				height: 8px;
				top: 3px;
			}
		}
	}

	&.-menu {

		li {

			margin: 0;
			padding: 0;
			border-top: 1px solid #fff;

			&:first-child {
				border: 0;
			}

			&:before {
				display: none;
			}

			a {
				display: block;
				background: @gray-lightest;
				padding: 10px;
				text-decoration: none;

				&:hover {
					text-decoration: none;
					background: @gray-light;
					color: #fff;
				}

				&.-active {
					background: @gray;
					color: #fff;
					font-weight: bold;
				}
			}
		}
	}
}