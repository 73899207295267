
.mainMenu {
	display: block;
	max-height: 0;
	overflow: hidden;

	&.-open {
		max-height: 500px;
		margin: 20px 0;
	}

	@media @tablet {
		display: block;
		max-height: none;
		margin: 20px 0 0 0;
	}

	ul {
		@media @tablet {
			padding: 0;
			display: table;
			width: 100%;
		}

		li {

			margin-top: 1px;

			&:first-child {
				margin-top: 0;
			}

			@media @tablet {
				display: table-cell;
			}

			a {
				display: block;
				padding: 7px 10px;
				background: @gray-lightest;
				text-align: center;
				font-family: @NudistaMedium;
				font-size: 16px;
				text-decoration: none;

				@media @tablet {
					background: @gray-light;
					text-transform: uppercase;
					color: #fff;
					padding: 10px 5px;
					font-size: 14px;
				}

				&.active {
					background: @gray-lighter;
					color: #fff;
				}

				&:hover {
					text-decoration: none;
					background: @blue;
					color: #fff;
				}
			}
		}
	}
}
