
.header .searchForm {
	max-height: 0;
	overflow: hidden;
	clear: both;
	display: none;

	&.-open {
		max-height: 100px;
		padding-top: 20px;
		display: block;
	}

	@media @tablet {
		clear: none;
		max-height: none;
		width: 250px;
		padding-left: 10px;
		.searchTop {
			float: right;
		}
	}

	@media @desktop-small {
		min-width: 0;
		padding-left: 0;
		padding-top: 25px;
	}

	.oneLine {
		position: relative;
		border: 1px solid @gray-light;
		border-right: 0;
		display: table;
		width: 100%;

		@media @tablet {
			width: auto;
		}

		@media @desktop-small {
			width: 100%;
		}

		.input {
			display: table-cell;

			@media @tablet {
				max-width: 200px;
			}

			@media @desktop-small {
				max-width: 150px;
			}

			input[type=text] {
				border: 0;
				outline: none;
			}
		}

		.submit {
			display: table-cell;
			width: 1px;

			.button {
				margin: 0;
			}
		}
	}
}