/* Resets */
*, *:after, *:before {
	.box-sizing(border-box);
}

html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre, h
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, figure, footer, header, hgroup, menu, nav, section,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	outline: 0;
	font-size: 100%;
	vertical-align: baseline;
	background: transparent;
}

article, aside, figure, footer, header, hgroup, nav, section, main {
	display: block;
}

nav ul {
	list-style: none;
	margin: 0;
}

blockquote, q {
	quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}

a {
	margin: 0;
	padding: 0;
	font-size: 100%;
	vertical-align: baseline;
	background: transparent;
}

ins {
	background-color: #ff9;
	color: #000;
	text-decoration: none;
}

mark {
	background-color: #ff9;
	color: #000;
	font-style: italic;
	font-weight: bold;
}

del {
	text-decoration: line-through;
}

abbr[title], dfn[title] {
	border-bottom: 1px dotted #000;
	cursor: help;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

hr {
	display: block;
	height: 1px;
	border: 0;
	border-top: 1px solid #ccc;
	margin: 1em 0;
	padding: 0;
}

label, input[type=button], input[type=submit], button {
	cursor: pointer;
}

input[type="radio"] {
	vertical-align: middle;
}

/* Abbreviations */
abbr[title] {
	border-bottom: 1px dotted;
}

/* Audio */
audio, canvas, video {
	display: inline-block;
}

audio:not([controls]) {
	display: none;
	height: 0;
}

/* Small, Sub and Sup */
small {
	font-size: 80%;
}

sub, sup {
	font-size: 75%;
	line-height: 0;
	position: relative;
	vertical-align: baseline;
}

sup {
	top: -.5em;
}

sub {
	bottom: -.25em;
}

/* Definition */
dfn {
	font-style: italic;
}

/* Images */
img {
	border: 0;
	vertical-align: middle;
	-ms-interpolation-mode: bicubic;
}

svg:not(:root) {
	overflow: hidden;
}

figure {
	margin: 0;
}

/* Horizontal Rule */
hr {
	border: 0;
	background: none;
	outline: 0;
	background-color: #ccc;
	margin: 2em 0;
	*margin: 1em 0;
	-moz-box-sizing: content-box;
	box-sizing: content-box;
	height: 1px;
}

/* Block Quotes and Quotes */
blockquote {
	font-size: 22px;
	line-height: 32px;
	color: #434343;
	margin: 1em 0;
	font-style: italic;
	p {
		margin: 1em 0;
	}
}

cite {
	color: #434343;
	font-style: normal;
	line-height: normal;
	font-size: 16px;
	line-height: normal;
}

q {
	font-style: italic;
	quotes: none;
}

q:before, q:after {
	content: '';
	content: none;
}

/* Mark */
mark {
	background: #ff0;
	color: #111;
}

/* Code */
code, kbd, pre, samp {
	word-wrap: break-word;
	font-family: 'courier new', monospace, serif;
	line-height: normal;
	font-weight: normal;
	background-color: #f1f1f1;
	padding: 10px;
}

code {
	color: #111;
}
