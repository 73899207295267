@font-face {
	font-family: "NudistaMedium";
	src: url("@{url-fonts}nudista_medium-webfont.eot");
	src: url("@{url-fonts}nudista_medium-webfont.eot?#iefix") format("embedded-opentype"),
	url("@{url-fonts}nudista_medium-webfont.woff") format("woff"),
	url("@{url-fonts}nudista_medium-webfont.ttf") format("truetype"),
	url("@{url-fonts}nudista_medium-webfont.svg#NudistaMedium") format("svg");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: CharterITCW04;
	src: url(/public/assets/dist/fonts/CharterITCW04-Bold.woff2) format('woff2'),
	url(/public/assets/dist/fonts/CharterITCW04-Bold.woff) format('woff'),
	url(/public/assets/dist/fonts/CharterITCW04-Bold.ttf) format('truetype'),
	url(/public/assets/dist/fonts/CharterITCW04-Bold.svg#CharterITCW04-Bold) format('svg');
	font-weight: 700;
	font-style: normal
}