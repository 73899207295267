form {
	fieldset {

	}

	.placeholder() {
		color: @gray;
		font-family: @Arial;
	}

	::-webkit-input-placeholder {
		.placeholder();
	}
	::-moz-placeholder {
		.placeholder();
	}
	:-ms-input-placeholder {
		.placeholder();
	}
	input:-moz-placeholder {
		.placeholder();
	}

	input[type=text],
	textarea,
	select,
	select option {
		font-size: @default-font-size;
		line-height: @default-line-height;
		color: #000;
		font-family: @Arial;
		padding: 6px 10px;
	}

	.error {
		display: inline-block;
		padding: 3px 0 0 0;
	}

	.input,
	.select,
	.textarea {
		position: relative;

		label {
			display: none;
		}
	}

	.input {
		input[type=text] {
			border: 1px solid @gray-light;
			width: 100%;
		}
	}

	.select {
		select {
			border: 0;
			width: 100%;
			color: @gray;
		}
	}

	.textarea {
		textarea {
			border: 0;
			width: 100%;
			resize: vertical;
		}
	}

	input[type=submit],
	button[type=submit] {
		border: 0;
		color: #fff;
		padding: 10px 20px;
		font-size: 15px;
		font-weight: normal;
		text-transform: uppercase;
		cursor: pointer;
	}

	.formRow {
		margin: 15px 0 0 0;

		&:first-child {
			margin-top: 0;
		}

		.leftCol {
			@media @phablet {
				float: left;
				width: 48%;
			}
		}

		.rightCol {
			margin-top: 15px;

			@media @phablet {
				margin-top: 0;
				float: right;
				width: 48%;
			}
		}
	}
}