._tal {
	text-align: left;
}

._tac {
	text-align: center;
}

._tar {
	text-align: right;
}

._uc {
	text-transform: uppercase;
}

._blue {
	color: @blue;
}