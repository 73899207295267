.ico {
	display: inline-block;
	vertical-align: middle;
}

.icoGroup {
	background: url("@{url-images}ico_group.png") 0 0 no-repeat;
	width: 26px;
	height: 16px;
	position: relative;
	top: -3px;
	margin-right: 7px;
	margin-left: -5px;
	@media @retina {
		background-image: url("@{url-images}ico_group@2.png");
		background-size: 100% auto;
	}
}