.cols {

	@media @phablet {
		text-align: justify;

		&:before {
			display: block;
			content: "";
		}

		&:after {
			display: inline-block;
			width: 100%;
			content: "";
		}
	}

	.col {

		&-1-1 {
			display: block;
			width: 100%;
			text-align: left;
			margin-top: 30px;

			&:first-child {
				margin-top: 0;
			}
		}

		&-1-3 {

			display: inline-block;
			vertical-align: top;
			width: 100%;
			margin-top: 30px;
			text-align: left;

			&:first-child {
				margin-top: 0;
			}

			@media @phablet {
				width: 48%;

				&:nth-child(2) {
					margin-top: 0;
				}
			}

			@media @tablet {
				width: 31%;

				&:nth-child(3) {
					margin-top: 0;
				}
			}
		}

		&-2-3 {

			display: inline-block;
			vertical-align: top;
			width: 100%;
			margin-top: 30px;
			text-align: left;

			&:first-child {
				margin-top: 0;
			}

			@media @phablet {

				&:nth-child(2) {
					margin-top: 0;
				}
			}

			@media @tablet {
				width: 62%;
			}
		}

		&-1-2 {
			display: inline-block;
			vertical-align: top;
			width: 100%;
			margin-top: 30px;
			text-align: left;

			&:first-child {
				margin-top: 0;
			}

			@media @phablet {

				width: 48%;

				&:nth-child(2) {
					margin-top: 0;
				}
			}
		}
	}
}

@media @tablet {
	.lCol {
		float: left;
		width: 48%;
	}
	.rCol{
		float: right;
		width: 48%;
	}
}