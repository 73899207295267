#contactSearchButtons {
	float: right;
}

#contactSearchButtons .reset {
	margin-top: 5px;
	cursor: pointer;
}

.topAligned * {
	vertical-align: top;
}

.dojoxMultiSelect {
	background: #fff;
	border: 1px solid #cecece !important;
	height: auto;
	min-height: 60px;
	max-height: 100px;
}

.btnBlue {
	font-size: 12px;
	color: #fff;
	text-decoration: none;
	background-color: #00518f;
	background-image: -webkit-linear-gradient(100% 100% 90deg, #047, #0067b5);
	background-image: -moz-linear-gradient(100% 100% 90deg, #047, #0067b5);
	background-image: linear-gradient(100% 100% 90deg, #047, #0067b5);
	border: 0 none;
	margin: 0 2px;
	padding: 2px 8px;
	border-radius: 3px;
}

.btnOrange {
	font-size: 12px !important;
	color: #fff;
	text-decoration: none;
	background-color: #f82;
	background-image: -webkit-linear-gradient(100% 100% 90deg, #ef7123, #ffac36);
	background-image: -moz-linear-gradient(100% 100% 90deg, #ef7123, #ffac36);
	background-image: linear-gradient(100% 100% 90deg, #ef7123, #ffac36);
	border: 0 none;
	padding: 2px 8px !important;
	border-radius: 3px;
	text-transform: none !important;
}

.contactsRP {

}

.contactsRP .controls {
	background-color: #f4f4f4;
	color: #111;
	font-size: 14px;
	border-bottom: 1px dotted #848896;
	float: left;
	clear: both;
	padding: 15px 10px 10px;
	width: 100%;
	margin-bottom: 15px;
}

.contactsRP .controls .fRow {
	margin-bottom: 5px;
}

.contactsRP .controls select,
.contactsRP .controls input[type="text"] {
	width: 155px;
	padding: 0 2px;
}

.contactsRP .controls input[type="submit"] {
	padding: 2px 8px;
	font-size: 12px;
	text-transform: none;
}

.contactsRP .contactList {
	width: 100%;
	overflow: auto;
	clear: both;
	float: left;
	margin-bottom: 10px;
	padding-bottom: 20px;
	overflow-y: hidden; /* ie7 vertical scrollbar fix */
}

.contactsRP .contactList table {
	width: 100%;
	border-collapse: collapse;
}

.contactsRP #contacts tr.firstRow td.check {
	width: 20px;
}

.contactsRP .contactList table img.rgt {
	margin: 0;
}

.contactsRP #contacts {
	overflow: hidden;
	clear: both
}

.contactsRP #contacts tr.firstRow th,
.contactsRP #contacts tr.firstRow td {
	padding-top: 20px;
}

.contactsRP #contacts th,
.contactsRP #contacts td {
	text-align: left;
	background-color: #f4f4f4;
	color: #2e2e2e;
	padding: 2px 5px 1px;
	vertical-align: top;
}

.contactsRP #contacts tr.dotted th,
.contactsRP #contacts tr.dotted td,
.contactsRP #contacts th.dotted,
.contactsRP #contacts td.dotted {
	border-bottom: 1px dotted #848896;
	padding-bottom: 10px;
}

.contactsRP #contacts td.spacer {
	padding: 0 10px;
}

.contactsRP #contacts td.spacer.last {
	padding: 0 10px 0 0;
}

.contactsRP #contacts tr.firstRow td.check {
	padding: 15px 10px 0;
	vertical-align: top;
	background: #e3e3e3 url("@{url-images}bg-check-shade.png") repeat-y 100% 0;
	border-right: 1px solid #fff;
}

.contactsRP #contacts tr.itemSep th,
.contactsRP #contacts tr.itemSep td,
.contactsRP #contacts th.itemSep,
.contactsRP #contacts td.itemSep {
	/*border-bottom:4px solid #fff;*/
	padding-bottom: 15px;
	background: #f4f4f4; /* Old browsers */
	background: -moz-linear-gradient(top, #f4f4f4 0%, #f4f4f4 79%, #f0f0f0 92%, #e9e9e9 100%); /* FF3.6+ */
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #f4f4f4), color-stop(79%, #f4f4f4), color-stop(92%, #f0f0f0), color-stop(100%, #e9e9e9)); /* Chrome,Safari4+ */
	background: -webkit-linear-gradient(top, #f4f4f4 0%, #f4f4f4 79%, #f0f0f0 92%, #e9e9e9 100%); /* Chrome10+,Safari5.1+ */
	background: -o-linear-gradient(top, #f4f4f4 0%, #f4f4f4 79%, #f0f0f0 92%, #e9e9e9 100%); /* Opera11.10+ */
	background: -ms-linear-gradient(top, #f4f4f4 0%, #f4f4f4 79%, #f0f0f0 92%, #e9e9e9 100%); /* IE10+ */
	background: linear-gradient(top, #f4f4f4 0%, #f4f4f4 79%, #f0f0f0 92%, #e9e9e9 100%); /* W3C */
}

.contactsRP #contacts tr.rowSep th,
.contactsRP #contacts tr.rowSep td {
	padding-top: 10px;
}

.contactsRP #contacts td.w170 {
	width: 200px;
}

.contactsRP #contacts tr.firstRow td.photo {
	width: 80px;
	padding: 10px 10px 0 10px
}

.contactsRP #contacts th.210 {
	width: 180px;
}

.contactsRP #contacts td.colSep {
	background: #f4f4f4 url("@{url-images}bg-colsep.png") repeat-y 50% 0;
}

.contactsRP .controls2 {
	float: left;
	width: 100%;
	margin-bottom: 25px;
}

.contactsRP h3 {
	font-size: 15px;
	font-weight: bold;
	margin: 20px 0 10px;
}

.contactsRP textarea {
	width: 660px;
	height: 145px;
	font-family: Arial, Helvetica, sans-serif;
}

.contactsRPDetail table {
	table-layout: fixed;
}

.searchResults.searchTop {
	overflow: hidden
}

.contactsRPDetail .contactData {
	width: 430px;
	float: left;
}

.contactsRPDetail .contactImg {
	padding: 20px;
	float: right;
	width: 206px;
	background-color: #f4f4f4;
}

.contactsRPDetail .contactImg img {
	border: 1px solid #cdcdcd;
	padding: 2px;
	display: inline-block;
	background-color: #fff;
	margin: 0;
}

.contactsRPDetail table {
	width: 100%;
	float: left;
	padding-bottom: 15px;
	border-bottom: 1px dotted #848896;
	margin-bottom: 15px;
}

.contactsRPDetail th,
.contactsRPDetail td {
	text-align: left;
	font-size: 1.3em;
}

.contactsMap {
	max-width: 100%;
}

.contactsRP {

	&__search {
		margin-bottom: 45px;
		.oneLine {
			position: relative;
			border: 1px solid @gray-light;
			border-right: 0;
			display: table;
			width: 100%;

			@media @tablet {
				width: auto;
				margin-left: auto;
				margin-right: auto;
			}

			@media @desktop-small {
				width: 100%;
				max-width: 350px;
				margin: auto;
			}

			.input {
				display: table-cell;
				input[type="text"] {
					padding-right: 25px;
				}
				@media @tablet {
					max-width: 200px;
				}

				@media @desktop-small {
					max-width: 150px;
				}

				input[type=text] {
					border: 0;
					outline: none;
				}
			}

			.submit {
				display: table-cell;
				width: 1px;

				.button {
					margin: 0;
				}
			}

			.reset-search {
				position: absolute;
				text-decoration: none;
				right: 104px;
				font-size: 24px;
				width: 14px;
				height: 12px;
				line-height: 12px;
				top: 50%;
				margin-top: -5px;
				color: @gray;
			}
		}
	}

	.error {
		color: #f00;
		text-align: center;
		margin-bottom: 15px;
	}
}

.contactsMap {
	max-width: 100%;
	margin-bottom: 35px;
}

.contact-infoBox {

	td, th {
		padding: 5px;
	}

	th {
		text-align: left;
	}
}