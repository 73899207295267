header.header {
    margin-bottom: 20px;
    margin-top: 20px;
    position: relative;
}

.header__items {
    position: relative;
}

// header layout
.header {
    .logos,
    .mobileTriggers {
        display: table;

        & > * {
            display: table-cell;
            vertical-align: bottom;
        }
    }

    .logos {
        margin-right: 110px;
    }

    .mobileTriggers {
        position: absolute;
        bottom: 0;
        right: 0;
    }
}

// logos layout
.header {
    .logo {
        &.-partner {
            max-width: 110px;
            padding-left: 0;

            img {
                max-height: 70px;
            }
        }

        &.-rn {
            padding-left: 15px;

            a {
                border-left: 1px solid #71C5E8;
                display: inline-block;
                padding-left: 15px;
            }

            img {
                width: 110px;
            }
        }
    }

    &.header__pompa {

        .logo.-partner {
            max-width: 217px;
        }

        .logo.-rn {
            display: none;

            a {
                border-left: 0;
            }

            @media @phablet {
                display: table-cell;
                text-align: right;
            }
        }

        img {
            max-height: 40px;
        }
    }
}

// triggers layout
.header {
    .mobileSearchToggle,
    .mobileMenuToggle {
        text-align: center;
        width: 35px;

        a {
            display: inline-block;
            font-size: 30px;
            line-height: 0;

            span {
                display: none;
            }
        }

        .icon {
            margin: 0;
        }
    }

    .mobileSearchToggle {
        a {
            font-size: 25px;
        }
    }

    .mobileMenuToggle {
        position: relative;
        top: 3px;

        a {

            margin-right: -9px;
        }
    }

}

// desktop layout
@media @tablet {
    header.header {
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: flex-end;
        -ms-flex-align: end;
        align-items: flex-end;
        -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
        justify-content: space-between;
    }

    .header__items {
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: flex-end;
        -ms-flex-align: end;
        align-items: flex-end;
        -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -webkit-flex: 0 1 72%;
        -ms-flex: 0 1 72%;
        flex: 0 1 72%;
    }

    .header {
        .logos {
            max-width: 390px;
            float: left;
            margin: 0;
        }

        .headerClaim {
            -webkit-flex: 0 1 53%;
            -ms-flex: 0 1 53%;
            flex: 0 1 53%;
            text-align: right;
        }

        .searchForm.searchForm {
            display: block;
        }

        .mobileTriggers {
            display: none;
        }
    }

    .header.header__pompa {

        .logos {
            width: 85%;
            max-width: none;
        }

        .logo.-partner {
            max-width: 224px;
        }
    }
}

@media @desktop-small {
    .header {
        .logo {
            &.-partner {
                max-width: 170px;
            }

            &.-rn {
                padding-left: 20px;

                a {
                    padding-left: 20px;
                }

                img {
                    width: 140px;
                    max-width: none;
                }
            }
        }

        // exceptions for partner webs
        &.header__kk-broker,
        &.header__royal-magnum {
            .logo.-partner {
                max-width: 200px;
            }
        }

        &.header__honesta-tres {
            .logo.-partner {
                max-width: 210px;
            }
        }

        &.header__finexcare {
            .logo.-partner img {
                position: relative;
                top: 7px;
            }
        }

        .headerClaim {
            -webkit-flex: 0 1 47%;
            -ms-flex: 0 1 47%;
            flex: 0 1 47%;
            text-align: right;
        }
    }
}

// claims styling
.header .headerClaim {
    display: none;
    font-family: "CharterITCW04", "Arial CE", Arial, Helvetica, sans-serif;
    color: #71C5E8;
    line-height: normal;

    @media @tablet {
        font-size: 22px;
        line-height: .7em;
        display: block;
    }

    @media @desktop-small {
        text-align: right;
        font-size: 25px;
    }
}

.header__pompa .headerClaim {
    display: none;
}

.mainClaim {
    font-size: @default-font-size * 1.6;
    font-family: "CharterITCW04", "Arial CE", Arial, Helvetica, sans-serif;
    color: #71C5E8;
    font-weight: bold;
    margin: 40px 0 40px 0;
    vertical-align: middle;
    text-transform: none;

    @media @phablet {
        font-size: @default-font-size * 2;
    }

    @media @tablet {
        display: none;
    }
}
