.columnLeft,
.columnMain {
	margin-top: 30px;

	@media @tablet {
		margin-top: 0;
	}

	&:first-child {
		margin-top: 0;
	}
}

.columnLeft {

	*:first-child {
		margin-top: 0;
	}

	@media @tablet {
		float: left;
		width: 25%;
	}
}

.columnMain {

	@media @tablet {
		float: right;
		width: 71%;
	}
}

#searchStatistic {
	padding: 20px 3px 24px 20px;
	border: 1px solid #ECECEC;
	border-bottom: 0;
	background: #F0F5F8 url("@{url-images}magnifier.png") 21px 61px no-repeat;
}

#searchStatistic .title {
	margin-bottom: 16px;
	background: url("@{url-images}arrow-doubleBlue.png") 97% 6px no-repeat;
}

#searchStatistic .row {
	font-size: .92em;
	margin: 0 0 3px 36px;
}

#searchStatistic .label {
}

#searchStatistic .value {
	color: #054681;
}

#searchForm fieldset {
	background: #FEFEFE;
	border: 1px solid #F2F2F2;
	border-top: 1px solid #E6E6E6;
	padding: 10px 20px 20px;
	margin-bottom: 20px;
}

#searchForm label {
	display: block;
	clear: both;
	color: #235f91;
	font-size: 1.4em;
	margin: 6px 0 17px;
}

#searchForm input {
	float: left;
	color: #111;
}

#searchForm input[type="text"] {
	text-shadow: 1px 1px 2px #afafaf;
	width: 110px;
}

#searchForm input[type="submit"] {
	color: #FFF;
}

.columnLeft #searchForm input[type="submit"] {
	padding: 7px 14px 8px 14px;
}

#searchForm .fRow {
	padding: 5px 0;
}

#searchForm .inlineLabel {
	font-size: 1.3em;
}

.searchResults p {

}

.searchResultItem {
	position: relative;
	margin-bottom: 23px;
}

.searchResultItem .counter {
	position: absolute;
	top: 0;
	left: -40px;
	width: 30px;
	text-align: right;
	color: #235f91;
	font-size: 1.17em;
}

.searchResultItem h3 {
	font-size: 1em;
	margin-bottom: 0;
	font-weight: bold;
	font-family: 'Arial CE', Arial, Helvetica, sans-serif;
	line-height: 1.8em;
}

.searchResultItem p {

}

.searchResultItem .visual {
	float: right;
}
