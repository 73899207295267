.arrow(@size) {
	position: absolute;
	top: 50%;
	content: "";
	display: inline-block;
	border: ~"@{size}px solid transparent";
	margin-top: ~"-@{size}px";
}

.arrow-left(@color) {
	border-right-color: @color;
}

.arrow-right(@color) {
	border-left-color: @color;
}

.gradient-diagonal(@color1, @color2) {
	background: @color1; /* Old browsers */
	background: -moz-linear-gradient(-45deg, @color1 0%, @color1 49%, @color2 50%, @color2 100%, @color2 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(-45deg, @color1 0%, @color1 49%, @color2 50%, @color2 100%, @color2 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(135deg, @color1 0%, @color1 49%, @color2 50%, @color2 100%, @color2 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='@{color1}', endColorstr='@{color2}', GradientType=1); /* IE6-9 fallback on horizontal gradient */

}

.box-sizing(@sizing) {
	-webkit-box-sizing: @sizing;
	-moz-box-sizing: @sizing;
	box-sizing: @sizing;
}

.transition (@transition) {
	-webkit-transition: @transition;
	-moz-transition: @transition;
	-ms-transition: @transition;
	-o-transition: @transition;
	transition: @transition;
}

.transform(@string) {
	-webkit-transform: @string;
	-moz-transform: @string;
	-ms-transform: @string;
	-o-transform: @string;
	transform: @string;
}

.transform-origin(@string) {
	-webkit-transform-origin: @string;
	-moz-transform-origin: @string;
	-ms-transform-origin: @string;
	-o-transform-origin: @string;
	transform-origin: @string;
}

.scale (@factor) {
	-webkit-transform: scale(@factor);
	-moz-transform: scale(@factor);
	-ms-transform: scale(@factor);
	-o-transform: scale(@factor);
	transform: scale(@factor);
}

.translate (@x, @y:0) {
	-webkit-transform: translate(@x, @y);
	-moz-transform: translate(@x, @y);
	-ms-transform: translate(@x, @y);
	-o-transform: translate(@x, @y);
	transform: translate(@x, @y);
}