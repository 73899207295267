.button {
	color: #fff;
	font-size: 25px;
	padding: 25px 50px;
	background: @gray;
	text-align: center;
	font-weight: normal;
	display: inline-block;
	margin: 0 1px;

	&:hover {
		background: darket(@gray, 5%);
	}

	&.-blue {
		background: @blue;
		color: #fff;

		&:hover {
			background: darken(@blue, 5%);
		}
	}
}