.languageSwitcher {
	overflow: hidden;
	margin-bottom: -20px;
	padding-top: 5px;
	font-size: 14px;
	ul {
		float: right;
		li {
			display: inline-block;
			margin: 0 0 0 10px;

			&:first-child {
				margin-left: 0;
			}

			a {
				text-transform: uppercase;
				text-decoration: none;

				&:hover {
					text-decoration: underline;
				}
			}
		}
	}
}