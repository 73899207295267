html {
	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
	-webkit-font-smoothing: antialiased;
	overflow-y: scroll;
}

body {
	background: #fff;
	color: @gray;
	margin: 0;
	padding: 0;
	font-size: @default-font-size;
	line-height: @default-line-height;
	font-weight: normal;
	font-family: @Arial;
}

html,
body {
	height: 100%;
}

h1,
h2,
h3,
h4 {
	strong {
		font-weight: normal;
	}
}

h1 {
	font-size: @default-font-size * 2.5;
	margin: 0 0 20px 0;
}

h2 {
	font-size: @default-font-size * 1.3;
	margin: 20px 0 0 0;
}

h1,
h2 {
	font-family: @NudistaMedium;
	font-weight: normal;
	text-transform: uppercase;
	color: @blue;
}

h3,
h4 {
	font-weight: bold;
}

h3 {
	font-size: @default-font-size * 1.2;
	margin: 15px 0 0 0;
	color: @blue;
}

h4 {
	font-size: @default-font-size;
}

em {
	font-style: normal;
}

img {
	max-width: 100%;
	vertical-align: middle;
	height: auto;
}

p {
	margin: 15px 0 0 0;
}

a {
	color: @blue;

	&:hover {
		text-decoration: none;
	}

	&[href^=tel] {
		&:hover {
			text-decoration: none;
			cursor: default;
		}
	}
}

ul {
	list-style: none;

	li {
		margin: 10px 0 0 0;
		padding: 0;
		position: relative;

		&:first-child {
			margin-top: 0;
		}
	}
}

.mainCol ul,
.documentText ul {
	list-style: disc;
	margin-left: 20px;
	li {
		list-style: none;
		position: relative;
		&:before {
			content: "";
			display: inline-block;
			width: 5px;
			height: 5px;
			background: @blue;
			position: absolute;
			top: 5px;
			left: -12px;
			border-radius: 50%;
		}
	}
	li,
	li:first-child {
		margin-top: 10px;
	}
}

.cf:before,
.cf:after {
	content: " ";
	display: table;
}

.cf:after {
	clear: both;
}

.cf {
	*zoom: 1;
}

.vihi {
	position: absolute;
	top: -99999px;
	left: -99999px;
}

.visuallyhidden,
.visuallyHidden,
.hide,
legend {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}

img.lft {
	float: left;
	margin: 0 20px 10px 0;
}

img.rgt {
	float: right;
	margin: 0 0 10px 20px;
}

.clear {
	clear: both;
}

.toggleTrigger {
	color: @blue;
	cursor: pointer;
	text-decoration: underline;
	&:hover {
		text-decoration: none;
	}
}

ul.pager.pager {

	margin: 15px 0 0 0;
	list-style: none;
	text-align: center;

	li {

		margin: 0;
		display: inline-block;
		vertical-align: middle;

		&:before {
			display: none;
		}

		strong,
		a {
			display: inline-block;
			padding: 5px;
			text-decoration: none;
			line-height: 1em;
		}
		a:hover {
			text-decoration: underline;
		}
		.prev,
		.next {
			font-size: 1.4em;
			line-height: .3em;
		}
	}
}