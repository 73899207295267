.mainCols {

	margin: 0 0 30px 0;

	@media @tablet {
		padding: 0 15px;
	}

	h2 {
		border-bottom: 1px solid @gray-light;
		margin: 0 0 10px 0;
		padding: 0 0 10px 0;
	}

	h3 {
		font-size: @default-font-size;
		font-weight: normal;
	}

	p {
		margin: 20px 0 0 0;
	}

	article {
		margin-top: 20px;

		h3 {
			margin: 0;
		}

		p {
			margin: 0;
		}

		&.reference {
			.name {
				margin-top: 10px;
			}
		}
	}

	.col {

		&-1-3 {

			position: relative;

			@media @phablet {
				padding-bottom: 35px;
			}

			.more {

				font-weight: bold;
				text-decoration: underline;

				@media @phablet {
					position: absolute;
					bottom: 0;
					left: 0;
				}

				&:hover {
					text-decoration: none;
				}
			}
		}
	}
}

.homepageContent {
	p {
		margin-bottom: 20px;
		margin-top: 0;
	}
	h3 {
		margin-bottom: 10px;
	}
	.moreLink {
		margin-top: 20px;
		display: block;
	}
}

.homepageContent .col-1-3.col-1-3 {
	padding-bottom: 0;
}

.moreLink {
	font-weight: bold;
}
